<template>
  <div class="h-screen bg-surface-50">
    <NavBarWorkspace />
    <main class="pl-64 h-full">
      <div class="h-full p-3 pl-0">
        <div
          class="h-full bg-white p-5 rounded-md border overflow-auto studio-scrollbar"
        >
          <slot />
        </div>
      </div>
    </main>
  </div>
</template>

<script setup></script>
